<template>
    <section class="content">
        <div class="overlay-wrapper">
            <div class="card card-primary">
                <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
                <div class="card-body">
					<div class="row form-horizontal align-items-center">
						<div class="col">
							<div class="btn btn-block text-left h6 bg-danger py-2 text-nowrap">Jumlah Saldo Tertahan: {{ totalRetainedFund }}</div>
						</div>
						<div class="col-auto mb-2">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text rounded-left d-flex align-items-center bg-white">
										<i class="fi fi-rr-calendar d-flex mr-2"></i>
										Tanggal dibuat
										<i class="fi fi-rr-angle-right d-flex ml-2"></i> 
									</div>
								</div>
								<input type="text" ref="daterange" class="form-control" />
							</div>
						</div>
                        <div class="col-auto mb-2 text-right">
                            <div class="btn-group" ref="toolbar">
                                <button type="button" :disabled="downloading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn btn-default d-none">
									<i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span>
								</button>
                                <button type="button" data-action="read" class="btn btn-default">
									<i class="fa fa-sync-alt"></i>
								</button>
                            </div>
                        </div>
					</div>
					<table class="table table-hover" ref="tableuser" id="tblriwayat">
						<thead>
							<tr>
								<th>Nomor Order</th>
								<th>Nomor Resi</th>
								<th>Asal Gudang</th>
								<th>Customer</th>
								<th>Tujuan</th>
								<th>Kurir</th>
								<th>Tanggal</th>
								<th>Saldo tertahan</th>
								<th>Status</th>
								<th>Ticket</th>
								<th>Claim</th>
							</tr>
						</thead>
						<tbody @click="handleClick">
						</tbody>
					</table>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment";
export default {
	name: "RetainedFund",
	data() {
		return {
			dateStart: '',
			dateEnd: '',
			errors: [],
			downloading: false,
			filter: {},
			dt1: moment().startOf("month"),
			dt2: moment(),
			downloading: false,
			processing: false,
			loadingContent: false,
            totalRetainedFund: 0,
		};
	},
	watch: {
		$route(to, from) {
			this.filter = {};
			this.table.api().ajax.reload();
		}
	},
	computed: {
		kurirLogo() {
			const fileName = "jne"; //logo.toLowerCase()

			return require(`../assets/img/${fileName}.png`); // the module request
		},
	},
	created: function () {
		if (this.$route.params.filter)
			this.filter = this.$route.params.filter;
        if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if (this.filter.gid) {
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
        this.filter.dt1 = !this.filter.dt1 ? moment().startOf("month").format("YYYY-MM-DD") : this.filter.dt1;
        this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;

		authFetch("/payment/saldo/total_retained_fund")
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.totalRetainedFund = "Rp. " + formatCurrency(js);
			});
	},
	methods: {
		infoClaim(claim){
			var statusClaim = '';
			if (claim == 1)
				statusClaim = '<span class="badge badge-info">Paket Diclaim</span>';
			else if (claim == 2)
				statusClaim = '<span class="badge badge-success">Claim disetujui</span>';
			else if (claim == 3)
				statusClaim = '<span class="badge badge-danger">Claim ditolak</span>';
			return statusClaim;
		},
		infoStatus(track) 
		{
			var output = '';
			if (track.cancel == 1)
				output += '<span class="badge badge-danger">Cancel</span>';
			else if (track.pickupStatus == 0)
				output += '<span class="badge badge-inverse">DRAFT</span>';
			else
			{
				if (track.trackingStatus == 0)
				{
					output += '<span class="badge badge-secondary">Menunggu Pickup</span>';
					output += this.infoClaim(track.claim);
				}
				else if (track.trackingStatus == 1)
				{
					output += '<span class="badge badge-warning">Proses</span>';
					output += this.infoClaim(track.claim);
				}
				else if (track.trackingStatus == 2)
				{
					output += '<span class="badge badge-success">Terkirim</span>';
					output += this.infoClaim(track.claim);
				}
				else if (track.trackingStatus == 3)
				{
					output += '<span class="badge badge-warning">Proses Retur</span>';
					output += this.infoClaim(track.claim);
				}
				else if (track.trackingStatus == 4) {
					if (track.retStatus == 1) 
					{
						output += '<span class="badge badge-success">Retur Diterima</span>';
						output += this.infoClaim(track.claim);
					}
					else if (!track.retStatus)
					{
						output += '<span class="badge badge-info">Retur Diserahkan</span>';
						output += this.infoClaim(track.claim);
					}
				}
				else if (track.trackingStatus == 5) 
				{
					if (track.problem_description != null){
						output += '<span class="badge badge-danger">Bermasalah - '+track.problem_description+'</span>';
					} else {
						output += '<span class="badge badge-danger">Bermasalah</span>';
					}
					output += this.infoClaim(track.claim);
				}
			}
			return output;
		},
		downloadXlsx: function (e) {
			this.downloading = true;
            var table = $('#tblriwayat').DataTable();
            var length = table.page.info().recordsTotal;
            
            if (length > 20000) {
                Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
                this.downloading = false;
                return false;
            }
            
			var filter = JSON.parse(JSON.stringify(this.filter));
                filter.q = $("input[name=q]").val();
                filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data)
            this.loadingContent = true;
			authFetch("/report/order/excell", {
					method: "POST",
					body: data,
				})
				.then((response) => {
                    if (response.status === 201) {
                    } else if (response.status === 400) {
                    } else if (response.status === 500){
                        Swal.fire("Timeout!", ``, "error");
                        this.loading2 = false;
                        this.msg = '';
                        return false;
                    }

                    return response.blob();
                })
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
                    this.loadingContent = false;
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "data.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
		},
		onDate() {
			this.table.api().ajax.reload();
		},
		getKurirLogo(kurir) {
			if (!kurir) return;
			const fileName = kurir.toLowerCase();
			return require(`../assets/img/${fileName}.png`); // the module request
		},
        dateDiff(dt1, dt2){
            let d1 = new Date(dt1);
            let d2 = new Date(dt2);

            const difference = d2.getTime() - d1.getTime();
            const days = Math.round(difference / (1000 * 60 * 60 * 24));
            return days;
        },
		handleClick(e) {
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/order/detail/" + e.target.dataset.id
				});
                window.open(route.href,"_blank");
				// return false;
			}
		},
	},
	mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Dana Tertahan'
		});
		const e = this.$refs;
		const self = this;

		new Daterangepicker(
			this.$refs.daterange, {
				startDate: this.dt1,
				endDate: this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
		var filterBy = [0, 1, 2, 3, 4, 5];
		this.table = createTable(e.tableuser, {
			title: "Data User",
			scrollX: true,
			ajax: "/payment/saldo/retained_fund",
			frame: false,
			toolbarEl: e.toolbar,
			lengthChange: true,
			filter: true,
			stateSave: true,
			dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
				"<'row'<'col-sm-12'tr>>" +
				"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			filterBy: filterBy,
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			"order": [
				[6, 'desc']
			],
			columns: [{
					data: "order_no",
					render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${row.order_no}</span>`);
					},
				},
				{ data: "no_resi" },
				{ data: "asal_gudang" },
				{ data: "customers" },
				{ data: "tujuan" },
				{
					data: "kurir",
					render: function (data, type, row, meta) {
						return '<img height="16" src="' + self.getKurirLogo(data.toLowerCase()) + '" />';
					},
				},
				{ data: "tanggal" },
				{
					data: "saldo_tertahan",
					render: function (data, type, row, meta) {
						return formatCurrency(data);
					},
				},
				{
					data: "status",
					render: function (data, type, row, meta) {
						return self.infoStatus(row);
					},
				},
				{
					data: "ticket_status",
					render: function (data, type, row, meta) {
						if (data == 0) {
							return "";
						} else if (data == 1) {
							return '<span class="badge badge-info">Open</span>';
						} else if (data == 2) {
							return '<span class="badge badge-success">Closed</span>';
						} else if (data == 3) {
							return '<span class="badge badge-warning">Pending</span>';
						} else {
							return "";
						}
					},
				},
				{
					data: "claim",
					render: function (data, type, row, meta) {
						return self.infoClaim(data);
					},
				},
			],
		});
	},
};
</script>
<style scoped>
.dataTables_length{padding-top: 0.5em;}
select.border-0.text-secondary:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
</style>